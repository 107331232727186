import React, { useState, useContext } from "react";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Avatar,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment";
//src
import { AuthContext } from "../Context/AuthContext";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import CreateAdjustmentTakeleave from "../Components/AdjustmentTakeleave/CreateAdjustmentTakeleave";
import UserAction from "../Components/User/UserAction";
import { GET_STAFFINFO_WITH_PAGINATION } from "../Schema/StaffInformation";

export default function AdjustmentTakeleave() {
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");
  const [currentSituation, setCurrentSituation] = useState("Working");

  const { refetch } = useQuery(GET_STAFFINFO_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      currentSituation: currentSituation,
      typeOfEmployment: [],
      sort: []
    },
    onCompleted: ({ getStaffInfoPagination }) => {
      setTableData(getStaffInfoPagination?.data);
      setPaginator(getStaffInfoPagination?.paginator);
      setLoading(false);
    },
    onError: (error) => {
      setAlert(true, "error", error?.message);
      // console.log(error)
    },
  });
  // console.log(tableData, "tableData");
  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" justifyContent="center">
            <Link className="link" to="/settings/general">
              General Setting
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">User</Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        {/* <Stack direction="column" justifyContent="center" spacing={2}>
          <Button
            onClick={handleOpen}
            className="btn-add"
            startIcon={<LibraryAddOutlinedIcon className="icon-add" />}
          >
            Create
          </Button>
        </Stack> */}

        <CreateAdjustmentTakeleave
          open={open}
          handleClose={handleClose}
          setRefetch={refetch}
          dialogTitle={"Create"}
        />
      </Stack>

      <Grid item container spacing={3} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            placeholder="search"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <FormControl fullWidth className="text-field" size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={currentSituation}
              label="Age"
              onChange={(e) => setCurrentSituation(e.target.value)}
            >
              <MenuItem value={"Working"}>Working</MenuItem>
              <MenuItem value={"Stop"}>Stop</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* <Grid item xs={6} md={4} lg={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid> */}
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">N°</TableCell>
              <TableCell className="header-title header-sticky-left">
                User
              </TableCell>
              <TableCell className="header-title" sx={{ minWidth: "140px" }}>
                Role
              </TableCell>
              <TableCell className="header-title">Email</TableCell>
              <TableCell className="header-title">Current Situation</TableCell>
              <TableCell
                className="header-title-end header-sticky-right"
                align="right"
              >
                Action
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={8}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={8}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + paginator?.slNo}
                          </TableCell>
                          <TableCell className="body-cell cell-sticky-left">
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                alt="Staff"
                                src={row?.staffProfile?.imageSrc}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography>
                                  {row?.staffProfile?.englishData
                                    ?.firstNameLatin +
                                    " " +
                                    row?.staffProfile?.englishData
                                      ?.lastNameLatin}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.systemAccessData?.role}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.systemAccessData?.emailAddress}
                          </TableCell>
                          <TableCell className="body-cell">
                            {/* {row?.systemAccessData?.isActive === true
                              ? "Working"
                              : "Stop"} */}
                            {row?.currentSituation}
                          </TableCell>
                          <TableCell
                            className="body-cell-end cell-sticky-right"
                            align="right"
                          >
                            <UserAction editData={row} setRefetch={refetch} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="8/Page"
          >
            <MenuItem value={6}>6/Page</MenuItem>
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
