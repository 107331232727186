import { gql } from "@apollo/client";

export const CREATE_STAFF_PROFILE = gql`
  mutation CreateStaffInfo($input: StaffInfoInput) {
    createStaffInfo(input: $input) {
      success
      message
    }
  }
`;

export const GET_STAFFINFO_WITH_PAGINATION = gql`
query GetStaffInfoPagination($page: Int, $limit: Int, $keyword: String, $pagination: Boolean, $currentSituation: String, $typeOfEmployment: [String], $sort: [sortField]) {
  getStaffInfoPagination(page: $page, limit: $limit, keyword: $keyword, pagination: $pagination, currentSituation: $currentSituation, typeOfEmployment: $typeOfEmployment, sort: $sort){
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
      data {
        _id
        currentSituation
        startedDate
        systemAccessData {
          emailAddress
          role
          isActive
        }
        staffProfile {
          imageName
          imageSrc
          englishData {
            firstNameLatin
            lastNameLatin
            gender
            identityCardEng
            passportsNumerEng
            phoneNumberEng
            nationalityEng
            dateOfBirthEng
            maritalStatusEng
            placeOfBirthEng {
              villageEng
              communeEng
              districtEng
              cityEng
            }
            permananceAddressEng {
              villageEng
              communeEng
              districtEng
              cityEng
            }
            temporaryAddressEng {
              villageEng
              communeEng
              districtEng
              cityEng
            }
          }
          khmerData {
            firstNameKh
            lastNameKh
            placeOfBirthKh {
              villageKh
              communeKh
              districtKh
              cityKh
            }
            permananceAddressKh {
              villageKh
              communeKh
              districtKh
              cityKh
            }
            temporaryAddressKh {
              villageKh
              communeKh
              districtKh
              cityKh
            }
          }
        }
        currentContract {
          _id
          startDate
          endDate
          typeOfEmployment
          positions {
            _id
            positionName
            positionNameKhmer
            inDepartment {
              _id
              departmentName
              remark
              createdAt
              updatedAt
            }
            isDepartmentManager
            remark
          }
          officeBase {
            _id
            officeBaseName
            remark
            createdAt
            updatedAt
          }
          workingBase {
            _id
            workingBaseName
            remark
            createdAt
            updatedAt
          }
          workingPlace {
            _id
            workingPlaceName
            remark
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const UPDATE_STAFF_INFORMATION = gql`
  mutation UpdateStaffInfo($input: StaffInfoInput, $id: ID) {
    updateStaffInfo(input: $input, _id: $id) {
      success
      message
    }
  }
`;

export const DELETE_STAFFINFORMATION = gql`
  mutation DeleteStaffInfo($id: ID) {
    deleteStaffInfo(_id: $id) {
      success
      message
    }
  }
`;

export const GET_STAFFINFO_BY_ID = gql`
  query GetStaffInfoById($id: ID!) {
    getStaffInfoById(_id: $id) {
      _id
      currentSituation
      startedDate
      systemAccessData {
        emailAddress
        role
        isActive
      }
      currentContract {
        _id
        startDate
        endDate
        typeOfEmployment
        positions {
          _id
          positionName
          positionNameKhmer
          inDepartment {
            _id
            departmentName
            remark
            createdAt
            updatedAt
          }
          isDepartmentManager
          remark
        }
        officeBase {
          _id
          officeBaseName
        }
        workingBase {
          _id
          workingBaseName
        }
        workingPlace {
          _id
          workingPlaceName
        }
      }
      staffProfile {
        imageName
        imageSrc
        englishData {
          firstNameLatin
          lastNameLatin
          gender
          identityCardEng
          passportsNumerEng
          phoneNumberEng
          nationalityEng
          dateOfBirthEng
          maritalStatusEng
          placeOfBirthEng {
            villageEng
            communeEng
            districtEng
            cityEng
          }
          permananceAddressEng {
            villageEng
            communeEng
            districtEng
            cityEng
          }
          temporaryAddressEng {
            villageEng
            communeEng
            districtEng
            cityEng
          }
        }
        khmerData {
          firstNameKh
          lastNameKh
          placeOfBirthKh {
            villageKh
            communeKh
            districtKh
            cityKh
          }
          permananceAddressKh {
            villageKh
            communeKh
            districtKh
            cityKh
          }
          temporaryAddressKh {
            villageKh
            communeKh
            districtKh
            cityKh
          }
        }
      }
    }
  }
`;

export const GET_STUFF_INFO_FOR_PRINT = gql`
  query GetStaffInfoForPrint($staffId: ID) {
    getStaffInfoForPrint(staffId: $staffId) {
      firstNameLatin
      lastNameLatin
      emailAddress
      startedDate
      currentSituation
      currentPositoins
      gender
      identityCardEng
      passportsNumerEng
      phoneNumberEng
      nationalityEng
      dateOfBirthEng
      maritalStatusEng
      placeOfBirthEng {
        villageEng
        communeEng
        districtEng
        cityEng
      }
      permananceAddressEng {
        villageEng
        communeEng
        districtEng
        cityEng
      }
      temporaryAddressEng {
        villageEng
        communeEng
        districtEng
        cityEng
      }
      workInPEPY {
        positionName
        officeBaseName
        startDate
        endDate
      }
      educationBackground {
        level
        majorName
        universityName
        graduactionDate
      }
      workHistory {
        positions
        workingPlace
        startDate
        endDate
      }
      covidVaccination {
        dosesNumber
        vaccinName
        vaccinationDate
      }
      drivingLicense {
        drivingId
        manualOrAuto
        drivingType
        expiryDate
      }
    }
  }
`;
