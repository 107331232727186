import React, { useState, useEffect, useContext } from "react";
// import "./activitylog.scss";
import "../Style/pagestyle.scss";
import {
  Typography,
  Stack,
  Box,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  TableHead,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  Avatar,
} from "@mui/material";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
//date picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
//src
import { AuthContext } from "../Context/AuthContext";
import EmptyData from "../Components/Include/EmptyData";
import LoadingPage from "../Components/Include/LoadingPage";
import { GET_ACTION_LOG_PAGINATION } from "../Schema/UserActions";

export default function ActivityLogs() {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  //query
  const [tableData, setTableData] = useState([]);
  const [paginator, setPaginator] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(6);
  const [keyword, setKeyword] = useState("");

  const { data, refetch } = useQuery(GET_ACTION_LOG_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      pagination: true,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
    },
    onCompleted: ({ getActionLogsWithPagination }) => {
      setLoading(false);
      setTableData(getActionLogsWithPagination?.data);
      setPaginator(getActionLogsWithPagination?.paginator);
    },
    onError: (error) => {
      // console.log(error)
      setAlert(true, "error", error?.message);
    },
  });

  // console.log("tableData::", tableData);

  const handleLimit = (e) => {
    setLimit(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [keyword, startDate, endDate]);

  //===================== Resize width Screen=====================
  const [width, setWidth] = React.useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" sx={{ height: "50px" }}>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">Activity Logs</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <Grid item container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            placeholder="search"
            size="small"
            className="text-field"
            onChange={(e) => setKeyword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={startDate}
              onChange={(e) => setStartDate(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="text-field"
                  size="small"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={endDate}
              onChange={(e) => setEndDate(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="text-field"
                  size="small"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start">N°</TableCell>
              <TableCell className="header-title">Username</TableCell>
              <TableCell className="header-title">Date</TableCell>
              <TableCell className="header-title">Action on</TableCell>
              <TableCell className="header-title-end" align="left">
                Action type
              </TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row-gray">
                  <TableCell className="body-cell" colSpan={7}>
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.length === 0 ? (
                  <TableRow className="body-row-gray">
                    <TableCell className="body-cell" colSpan={7}>
                      <EmptyData />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow
                          className={
                            (index + 1) % 2 === 0 ? "body-row" : "body-row-gray"
                          }
                          key={index}
                        >
                          <TableCell className="body-cell-start">
                            {index + paginator?.slNo}
                          </TableCell>
                          <TableCell className="body-cell">
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                alt="Staff"
                                src={row?.user?.staffProfile?.imageSrc}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography>
                                  {row?.user?.staffProfile?.englishData
                                    ?.firstNameLatin +
                                    " " +
                                    row?.user?.staffProfile?.englishData
                                      ?.lastNameLatin}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {moment(row?.updatedAt).format("DD-MMM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.actionOn}
                          </TableCell>
                          <TableCell className="body-cell-end" align="left">
                            <Stack direction="row" spacing={2}>
                              {row?.actionType === "Login" ? (
                                <IconButton
                                  sx={{
                                    bgcolor: "#13269D",
                                  }}
                                >
                                  <ExitToAppOutlinedIcon
                                    sx={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </IconButton>
                              ) : row?.actionType === "Created" || row?.actionType === "Approved" ? (
                                <IconButton
                                  sx={{
                                    bgcolor: "#198f4a",
                                  }}
                                >
                                  <CheckIcon
                                    sx={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </IconButton>
                              ) : row?.actionType === "Updated" || row?.actionType === "Edit Status" ? (
                                <IconButton
                                  sx={{
                                    bgcolor: "#5989F6",
                                  }}
                                >
                                  <ModeEditOutlineIcon
                                    sx={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </IconButton>
                              ) : row?.actionType === "Adjust" ? (
                                <IconButton
                                  sx={{bgcolor: "#E8A622"}}
                                >
                                  <AutoFixHighIcon 
                                    sx={{fontSize: "16px", color: "#fff"}}
                                  />
                                </IconButton>
                              ): (
                                <IconButton
                                  sx={{
                                    bgcolor: "#F65971",
                                  }}
                                >
                                  <CloseIcon
                                    sx={{ fontSize: "16px", color: "#fff" }}
                                  />
                                </IconButton>
                              )}

                              <Stack direction="column" justifyContent="center">
                                <Typography className="action-type">
                                  {row?.actionType}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Stack
          direction="row"
          justifyContent="right"
          spacing={2}
          sx={{ margin: "20px 30px 0px 0px" }}
        >
          <Stack direction="column" justifyContent="center">
            <Pagination
              hideNextButton={false}
              hidePrevButton={false}
              count={paginator?.totalPages}
              color="primary"
              variant="outlined"
              onChange={(event, pageNumber) => setPage(parseInt(pageNumber))}
            />
          </Stack>
          <Select
            size="small"
            value={limit}
            onChange={handleLimit}
            defaultValue="8/Page"
          >
            <MenuItem value={6}>6/Page</MenuItem>
            <MenuItem value={8}>8/Page</MenuItem>
            <MenuItem value={10}>10/Page</MenuItem>
            <MenuItem value={paginator?.totalDocs}>All/Page</MenuItem>
          </Select>
        </Stack>
      </Box>
    </div>
  );
}
